<template>
  <div class="container">
    <div class="py-4">
      <a href="#" @click="$router.go(-1)">
        Back
      </a>
    </div>
    <div class="row">
      <div class="col-12 mb-5 mt-5">
        <p dir="ltr">
          MONEY - BACK POLICY
        </p>
        <br>
        <p dir="ltr">
          This Money-back Policy applies to the purchases made on MathMaster website.
          Generally, the fees you have paid for the Services are non-refundable and/or
          non-exchangeable, unless otherwise is stated in this Refund Policy or required by
          applicable law.
        </p>
        <p dir="ltr">
          Note for the EU residents: If you are an EU resident, you have the right to withdraw
          from the agreement for the purchase of digital goods without charge and without giving
          any reason within fourteen (14) days from the date of such agreement conclusion,
          unless you provided consent to an immediate agreement performance.
          YOU HEREBY EXPRESSLY CONSENT AND ACKNOWLEDGE THAT THE PERFORMANCE OF THE AGREEMENT
          BEGINS IMMEDIATELY AND THAT YOU WILL LOSE YOUR RIGHT OF WITHDRAWAL FROM THE AGREEMENT
          ONCE OUR SERVERS VALIDATE YOUR PURCHASE AND THE APPLICABLE PURCHASE IS SUCCESSFULLY
          DELIVERED TO YOU. Therefore, if you cancel the purchase for any reason within
          14 calendar days from the date of purchase, you will not be entitled to a refund.
        </p>
        <p dir="ltr">
          However, we decided to provide you an opportunity to receive a refund (the “Voluntary
          Refund”)
          if you meet the conditions set out below.
        </p>
        <p dir="ltr">
          You can contact us at support@mathmaster.me to request a refund.
          We will review your application and notify you (by email) whether it is approved.
          If the application is approved, your refund will be processed, and a credit will
          be automatically applied to your credit card or original method of payment within
          14 days from the date of application.
        </p>
        <p dir="ltr">
          A refund can usually be claimed only during the subscription period.
          If the subscription period has expired before you made a request for a refund,
          we will not be able to provide you with a refund.
        </p>
        <br>
        <p dir="ltr">
          IMPORTANT STATEMENT
        </p>
        <br>
        <p dir="ltr">
          Please note that only fulfillment of the above requirements allows you to receive
          a complete Voluntary Refund under the “100% Money-back Guarantee” policy.
          For the sake of clarity, this “100% Money-back guarantee” does not apply
          to any other instances, including, but not limited to, the following cases:
        </p>
        <p dir="ltr">
          • we have proof of delivering all mails with products to your mailbox
          (according to your payments);
        </p>
        <p dir="ltr">
          • you think that you do not have enough time to read the answers contained
          in the personalized astrology report;
        </p>
        <p dir="ltr">
          This Refund Policy is incorporated into and is subject to the Terms
          and Conditions of Use unless otherwise provided herein.
        </p>
        <br>
        <p dir="ltr">
          Last updated: 20 March 2022
        </p>
      </div>
    </div>
    <div class="py-4">
      <routerLink to="/plans">
        Back
      </routerLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoneyBack',
};
</script>

<style scoped>

</style>
