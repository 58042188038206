<template>
  <div class="container">
    <div class="py-4">
      <a href="#" @click="$router.go(-1)">
        Back
      </a>
    </div>
    <div class="row">
      <div class="col-12 mb-5 mt-5">
        <p dir="ltr">
          SUBSCRIPTION TERMS
        </p>
        <p dir="ltr">
          1. Paid trial. We may offer a paid (for 1$) trial subscription (for 3 days)
          for the service. Unless you cancel it at least 24 hours before the end of
          the trial, you will be automatically charged a price according to chosen
          subscription period (69,99$ per half-year subscription). Please note that
          if a trial is offered, this will be explicitly stated on the price screen
          before the checkout. From time to time, we may also propose discounted
          offers and other offers that may be interesting for you.
        </p>
        <p dir="ltr">
          2. Subscription. The subscription renews automatically at the end of each
          period (each week or month or otherwise, depending on the option selected
          by you at the time of purchase) until you cancel.
        </p>
        <p dir="ltr">
          3. Payment method. Payment will be charged to the payment method you
          submitted at the time of purchase at confirmation of purchase (after you
          confirm by single-touch identification, facial recognition, or entering
          your payment method details on the web, or otherwise accepting subscription
          terms provided on the payment screen or on the pop-up screen provided by
          Apple/Google or on our web page) or after the end of the trial period. You
          authorize us to charge the applicable subscription fees to the payment
          method that you use.
        </p>
        <p dir="ltr">
          4. Cancelation. Your subscription renews automatically at the end of each
          period until you cancel.
        </p>
        <p dir="ltr">
          To avoid being charged cancel your subscription in time sufficient to
          process your request before the end of the period (which may take up to 5
          days). You may cancel your subscription via our support chat that is
          available through our website. The mail of support team:
          support@mathmaster.me
        </p>
        <p dir="ltr">
          In order to cancel your subscription, you will have to send our support
          team a message with the request.
        </p>
        <p dir="ltr">
          Canceling your subscription means that the automatic renewal will be
          disabled, but you will still have access to all your materials you have
          already paid for.
        </p>
        <p dir="ltr">
          5. Refunds.
        </p>
        <p dir="ltr">
          We provide refunds at our own discretion and subject to laws and our
          policies that may be published from time to time. Refund will be provided
          if we find the request acceptable.
        </p>
        <p dir="ltr">
          Please note that after your subscription period expires, we will not be
          able to refund you as the service will be deemed consumed in full, unless
          otherwise provided for by applicable law.
        </p>
        <p dir="ltr">
          6. Changes.
        </p>
        <p dir="ltr">
          To the maximum extent permitted by applicable laws, we may change
          subscription fees at any time. We will give you reasonable notice of any
          such pricing changes by posting the new prices on or through the app and/or
          by sending you an email notification, or in other prominent ways. If you do
          not wish to pay the new fees, you can cancel the applicable subscription
          prior to the change going into effect.
        </p>
        <p dir="ltr">
          If you have any questions in relation to the subscription terms, please
          contact us directly at support@mathmaster.me
        </p>
        <p dir="ltr">
          Please make a screenshot of this information for your reference. This may
          help you to control your subscriptions.
        </p>
        <p dir="ltr">
          Last update: 21 July 2021
        </p>
        <br/>
      </div>
    </div>
    <div class="py-4">
      <routerLink to="/plans">
        Back
      </routerLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubTerms',
};
</script>

<style scoped>

</style>
